<template>
    <v-row no-gutters>
        <v-col cols="12">
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Dynamic DNS shared domains</h1>
                <!-- <p class="text-caption text-center">{{ name }}</p> -->
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5">
                <v-col style="text-align: center" cols="12" sm="6">
                    <v-textarea :value="tinydnsconfig"></v-textarea>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10 mb-8">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card tile elevation="4" class="pa-0" v-if="dynamicSharedDomainList">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="purple--text">{{ dynamicSharedDomainList.length }} records</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon color="purple" @click="createNewItemDialog = true">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn>
                        </v-toolbar>

                        <v-list dense>
                            <v-list-item v-for="(item, idx) in dynamicSharedDomainList" :key="idx">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ item.name }}
                                        <v-chip v-if="item.status === 'new'" label small class="px-1 ml-2 yellow">{{ item.status }}</v-chip>
                                        <v-chip v-if="item.status === 'pending'" label small class="px-1 ml-2 blue lighten-4">{{ item.status }}</v-chip>
                                        <v-btn icon small color="purple darken-2" @click="openEditItemDialog(item)">
                                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width/>
                                        </v-btn>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-card>
                    <v-dialog v-model="createNewItemDialog" max-width="600">
                    <v-card tile elevation="4" class="pa-0" max-width="600">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="purple--text">Create new shared domain for Dynamic DNS</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form @submit.prevent="createItem" @keyup.enter.native.prevent="createItem" class="px-2">
                            <v-text-field v-model="newItemName" label="Domain name" hint="Must already be verified and added to the shared domain account" ref="newItemNameInput"></v-text-field>
                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="purple white--text" @click="createItem" :disabled="!isNewItemFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="createNewItemDialog = false">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                    <v-dialog v-model="editItemDialog" max-width="600">
                    <v-card tile elevation="4" class="pa-0" max-width="600">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="purple--text">Edit shared domain for Dynamic DNS</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                            <p class="text-overline">Domain</p>
                            <p>{{ editItemName }}</p>

                            <v-form @submit.prevent="editItem" @keyup.enter.native.prevent="editItem" class="px-2">
                                <v-select v-model="editItemStatus" label="Status" :items="statusChoices"></v-select>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="purple white--text" @click="editItem" :disabled="!isEditItemFormComplete">
                                <span>Save</span>
                            </v-btn>
                            <v-btn text color="grey" @click="editItemDialog = false">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        dynamicSharedDomainList: null,

        createNewItemDialog: false,
        newItemName: null,

        editItemDialog: false,
        editItemName: null,
        editItemStatus: null,
        statusChoices: [
            { value: 'new', text: 'New' },
            { value: 'pending', text: 'Pending' },
            { value: 'ready', text: 'Ready' },
        ],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        isNewItemFormComplete() {
            return typeof this.newItemName === 'string' && this.newItemName.length > 0;
        },
        isEditItemFormComplete() {
            return typeof this.editItemName === 'string' && this.editItemName.length > 0 && typeof this.editItemStatus === 'string' && this.statusChoices.findIndex((item) => item.value === this.editItemStatus) > -1;
        },
    },
    watch: {
        createNewItemDialog(newValue, oldValue) {
            if (newValue && !oldValue) {
                this.newItemName = '';
                this.$nextTick(() => {
                    setTimeout(() => { this.activate('newItemNameInput'); }, 1);
                });
            }
        },
    },
    methods: {
        activate(ref) {
            const inputRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref];
            if (inputRef) {
                // more than one way to do it:
                // 1. inputRef.focus();
                // 2. const inputElement = inputRef.$el.querySelector('input'); inputElement.focus();
                // 3. const inputElement = inputRef.$el.querySelector('input'); document.getElementById(inputElement.id).focus()
                inputRef.focus();
            }
        },
        async loadDynamicSharedDomainList() {
            try {
                this.$store.commit('loading', { loadDynamicSharedDomainList: true });
                const response = await this.$client.main().dynamicSharedDomain.search();
                if (response?.list) {
                    this.dynamicSharedDomainList = response.list;
                }
            } catch (err) {
                console.error('loadDynamicSharedDomainList failed', err);
            } finally {
                this.$store.commit('loading', { loadDynamicSharedDomainList: false });
            }
        },
        async createItem() {
            try {
                this.$store.commit('loading', { createItem: true });
                const item = {
                    name: this.newItemName,
                };
                const response = await this.$client.main().dynamicSharedDomain.create(item);
                if (response?.isCreated && response.item) {
                    this.createNewItemDialog = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    this.dynamicSharedDomainList.push(response.item);
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('createItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { createItem: false });
            }
        },
        async openEditItemDialog(item) {
            this.editItemName = item.name;
            this.editItemStatus = item.status;
            this.editItemDialog = true;
        },
        async editItem() {
            try {
                this.$store.commit('loading', { editItem: true });
                const response = await this.$client.main().dynamicSharedDomain.edit({ name: this.editItemName }, { status: this.editItemStatus });
                if (response?.isEdited) {
                    this.editItemDialog = false;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'OK' });
                    const idx = this.dynamicSharedDomainList?.findIndex((item) => item.name === this.editItemName);
                    if (Number.isInteger(idx) && idx > -1) {
                        this.dynamicSharedDomainList.splice(idx, 1, { name: this.editItemName, status: this.editItemStatus });
                    } else {
                        this.loadDynamicSharedDomainList();
                    }
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
                }
            } catch (err) {
                console.error('editItem failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed' });
            } finally {
                this.$store.commit('loading', { editItem: false });
            }
        },
    },
    mounted() {
        this.loadDynamicSharedDomainList();
    },
};
</script>
